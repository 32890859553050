.PolicyContainer {
  background-color: rgba(33, 17, 52, 1);
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  position: relative;
}

.privacy-title {
  color: #fff;
  text-align: center;
}

section {
  text-wrap: wrap;
  margin: 18px;
  color: #fff;
  text-align: justify;
}
