@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.welcomeContainer2 {
  margin: 0rem 0 10px 0;
  width: 93vw;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.LeftSide {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 95%;
  margin: 6px 0 5px 0;
  margin-top: 35px;
}
.ProfileMainDiv {
  display: flex;
  color: white;
  justify-content: space-between;
  /* margin-top: -36px; */
}
.profileInfoDiv {
  display: flex;
  align-items: center;
  gap: 5px;
}
.profileName {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  color: white;
  font-family: "Roboto", sans-serif;
}
.profileRankInfo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
.profileRankInfo span {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  color: white;
  font-family: "Roboto", sans-serif;
}
/* ======== */
.levelscore {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 11rem;
  margin-bottom: 1px;
  margin-top: 2px;
}
.progressBox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background: rgba(50, 54, 60, 1);
  position: relative;
  height: 16px;
  border-radius: 3rem;
  position: relative;
  margin-top: 5px;
  margin-left: -5px;
}

.greenBar {
  background: linear-gradient(90deg, #ff7a00 0%, #ffa114 45%, #87c835 100%);
  position: absolute;
  /* width: 80%; */
  height: 13px;
  width: 17px;
  left: 2.2px;
  top: 1.7px;
  border-radius: 3rem;
  transition: width 1.5s ease-in-out;
}
.barRank {
  height: 100%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.rank {
  font-size: 8px;
  position: absolute;
  font-family: "Oswald", sans-serif;
  color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}
.rank span {
  color: rgba(255, 255, 255, 1);
  font-size: 8px;
  z-index: 2;
  font-family: "Oswald", sans-serif;
}
.starIcon {
  color: rgba(255, 255, 255, 0.5);
  font-size: 9px;
  margin: 2px;
}

.arrowIcon {
  margin-left: 5px;
  backdrop-filter: blur(30px);
  border-radius: 3rem;
  border: 1px soli rgba(246, 5, 5, 0.1);
  padding: 3px;
  color: #ffffff;
  font-size: 15px;
}

.settingsIcon {
  height: 30px;
  height: 30px;
}

@media only screen and (max-width: 375px) and (max-height: 667px) {
  .welcomeContainer2 {
    margin: 0rem 0 10px 0;
  }
  .LeftSide {
    margin-top: 0px;
  }
  .ProfileMainDiv {
    margin-top: -2px;
  }
}
