.okx_container {
  padding: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.okxIconImg {
  height: 150px;
  width: 150px;
  object-fit: cover;
}
.okxSilverCoin {
  height: 30px;
  width: 30px;
}
