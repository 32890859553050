.glow-text {
  color: #24232d; /* Text color */
  /* background: url("../../media/Background.png") no-repeat; */
  background: url("../../media/snapshot.jpg");
  background-size: 100% 100%;
}

.maintenance-image-container {
  background: url("../../media/maintenance.jpeg");
  width: 90%;
  margin: 0 auto;
  background-position: center;
  position: absolute;
  top: 50;
}

.main-container {
  position: fixed;
  height: 100vh;
}
