/* Scoped styles for Popup component */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(76, 192, 192, 0.212); /* Updated background */
  display: flex;
  align-items: flex-end; /* Align popup to the bottom */
  justify-content: center;
  transition: opacity 0.3s ease; /* Smooth fade-in effect */
  backdrop-filter: blur(5px); /* Blurring the background when popup is open */
  /* Extremely high z-index with !important to ensure priority */
  isolation: isolate;
  z-index: 200 !important;
}

.popup-container {
  background: #21102ee5; /* Darker and less transparent */
  border-radius: 1rem 1rem 0 0; /* Rounded corners on the top */
  /* padding: 15px; */
  position: relative;
  width: 100%;
  height: 90vh;
  max-height: 95vh;
  text-align: center;
  box-sizing: border-box;
  overflow: auto; /* Enable scrolling if content overflows */
  transform: translateY(100%);
  transition: transform 0.7s cubic-bezier(0.25, 0.8, 0.25, 1); /* Smooth and natural animation */
  /* z-index: 999999999999999999999999999999 !important; Ensure the popup container is on top */
}

.popup-container.show {
  position: fixed;
  transform: translateY(0);
  /* height: "90vh"; */
  /* z-index: 999999999999999999999999999 !important; Bring the popup into view */
}

.popup-close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  transition: color 0.3s ease;
  z-index: 9999999 !important;
}

.popup-close-btn:hover {
  color: #f97316;
}
.notificationModalImg {
  width: 100%;
  height: 90%;
  object-fit: cover;
}
.btn_Notification {
  background: #bfa75d;
  color: #c9c9c9;
  font-weight: 800;
  border-radius: 15px;
  outline: none;
  padding: 8px 20px;
  height: 50px;
  text-align: center;
  border: none;
  font-size: 19px;
}
.popup-image {
  display: block;
  margin: 0 auto 1rem;
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.popup-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
  color: white;
}

.popup-icons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1.5rem;
}

.popup-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
.popup-icons img {
  height: 30px;
  width: 30px;
}

.popup-icon-text {
  font-size: 1rem;
  color: white;
}

.popup-claim-btn {
  background-color: #d87500; /* orange-500 */
  color: white;
  padding: 1rem 2rem;
  border: none; /* Remove border */
  border-radius: 9999px; /* Fully rounded corners */
  font-size: 1rem;
  font-style: "Arial";
  font-weight: bold;
  cursor: pointer;
  z-index: 9999999 !important; /* Make sure the button remains interactive */
  outline: none; /* Remove any focus outline */
}

.popup-description {
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.7);
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .popup-container {
    height: 78vh; /* Make the popup take up half of the screen on mobile */
    max-height: 95vh; /* Maximum 60% height on mobile */
    border-radius: 1rem 1rem 0 0; /* Keep rounded corners */
  }
  .inCreaseHeight {
    height: 93vh;
  }
}
