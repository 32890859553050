@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.becomeBossMain {
  background-color: #000000;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  /* overflow-y: scroll; */
  position: relative;
  font-family: "Roboto", sans-serif;
}
.boss_info {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.activeRank {
  background: #bfa75d;
  border-radius: 0px;
  color: white;
}
.boss_info_title {
  color: rgba(201, 201, 201, 1);
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  margin: 10px 0px;
}
.boss_RankBox {
  width: 90vw;
  background: rgba(0, 0, 0, 1);
  border: 1px solid transparent;
  background-image: linear-gradient(#000000, #000000),
    linear-gradient(90deg, #bfa75d 1.09%, #c9c9c9 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 10px;
  height: 125px;
  padding: 10px;
}
.rankBox_rankDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}
.boss_ceoRank {
  margin-top: 25px;
}
.rankDivLeftTitle {
  font-size: 12px;
  font-style: italic;
}
.rankDivLeftSubTitle {
  font-size: 11px;
}
.rankDivRightTitle {
  font-size: 12px;
  font-style: italic;
}
.rankDivRightSubTitle {
  font-size: 11px;
}

.boss_Table_container {
  height: 80%;
  margin-bottom: 20px;
}

.boss_table_section {
  height: 60%;
  margin: 20px auto;
}
.boss_table_section table {
  height: 250px;
}
.boss_table_section table tr {
  border-bottom: 2px solid gray;
  padding: 10px 0px;
}
.boss_table_section table tr td {
  border: none;
}
/* ============= */
