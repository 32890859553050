@import url("https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");

.miniGameMainDiv {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-image: url("../../../media/miniGame-bg.png");
  background-size: cover;
  background-position: center;
  padding: 0;
  display: flex;
  justify-content: center;
  position: relative;
}
.miniGameContentWrapper {
  width: 100%;
  margin: 15px 20px;
}

.topButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topButton button {
  font-size: 22px;
  color: #fff000;
  font-weight: 600;
  text-align: center;
  background: #3f0da5;
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 18px 30px;
  font-family: "Poetsen One";
}

.logoImgSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8%;
}

.TopImgSection {
  width: 266px;
  height: 200px;
  /* background-color: #6531c1; */
  border-radius: 50%;
}

.miniGameTopImg {
  width: 266px;
  height: 200px;
}

.winnerAmmountBox {
  text-align: center;
  color: #fff000;
  padding: 18px;
  font-size: 22px;
  margin-top: 3%;
  border-radius: 8px;
  font-weight: 400;
  font-family: "Poetsen One";
}
.gameCreditBox {
  background-color: #1e0256;
  color: #fff000;
  padding: 12px;
  margin-top: 15px;
  border-radius: 8px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.coinImage {
  width: 55px;
  height: 55px;
}
.creditTitle {
  font-family: "Poetsen One";
  font-weight: 400;
  font-size: 14px;
  color: white;
  margin-top: 8px;
  margin-bottom: 2px;
}

.ammountMainBox {
  margin-top: 10%;
}
.amount {
  font-size: 25px;
  font-weight: 600;
  font-family: "Oswald", sans-serif;
  color: white;
  margin-top: -5px;
  margin: 0;
  padding: 0;
}
.ticketMainBox {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}
.ticketBox {
  width: 30%;
  border-radius: 8px;
  background: #270b73;
  text-align: center;
  color: #fff000;
  font-size: 21px;
  font-family: "Poetsen One";
}
.ticketBox2 {
  width: 70%;
  font-size: 21px;
  border-radius: 8px;
  background: #270b73;
  text-align: center;
  color: #fff000;
  font-family: "Poetsen One";
}
.buttonSection {
  text-align: center;
  margin-top: 10%;
}
.playNowButton {
  border: none;
  outline: none;
  background-color: #fff000;
  padding: 18px 40px;
  text-align: center;
  border-radius: 8px;
  color: #7800bb;
  font-size: 20px;
  font-weight: 800;
  font-family: "Poetsen One";
}

@media only screen and (max-width: 429px) {
  .logoImgSection {
    margin-top: 2%;
  }
  .buttonSection {
    margin-top: 8%;
  }
  .ticketBox {
    font-size: 15px;
  }
  .ticketBox2 {
    font-size: 15px;
  }
  .TopImgSection {
    margin-top: -3%;
  }
  .winnerAmmountBox {
    margin-top: -14%;
  }
}

@media only screen and (min-width: 431px) {
  .logoImgSection {
    margin-top: 10%;
  }
  .buttonSection {
    margin-top: 12%;
  }
}
