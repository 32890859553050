@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');
html {
  touch-action: none;
}

.toaster-container {
  z-index: 12000 !important; /* Increase z-index to ensure it's in front */
}

body {
  margin: 0;
  font-family: "Oswald", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  user-select: none;
  /* background: rgba(33, 17, 52, 1); */
  -webkit-tap-highlight-color: transparent; /* or any color you prefer */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Hide the Google Translate floating popup */
.goog-te-banner-frame {
  display: none !important;
}

/* Hide the Google Translate toolbar (if visible) */
body {
  top: 0px !important;
}

/* Hide the Google popup at the bottom-right corner */
.goog-te-balloon-frame {
  display: none !important;
}

/* Optional: Prevent flash of original text during translation */
.goog-te-combo {
  display: none !important;
}
#goog-gt-tt {
  display: none !important;
}
.skiptranslate {
  display: none;
}

.gt_selector {
  background-color: transparent;
  width: 150px;
  border-radius: 0.375rem;
  color: white;
  padding: 8px 10px;
  font-size: 15px;
  appearance: none;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 1.5rem auto;
  transition: all 0.3s ease;
  background-color: #1d1c1d;
}
.stripe-button {
  position: relative;
  padding: 10px 24px;
  font-size: 18px;
  font-weight: 800;
  color: white;
  background-color: #ffc107; /* Yellow background */
  border: none;
  border-radius: 9px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  margin-top: 5px;
}

/* Slanted stripe effect */
.stripe-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    110deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%
  );
  background-size: 50px 50px;
  transform: translateX(-50%);
  transition: 0.3s ease-in-out;
}
