@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.airDropMainContainer {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background-color: rgba(28, 31, 36, 1);
  position: relative;
  font-family: "Roboto", sans-serif;
}
.airDropImg_section {
  height: 45vh;
  overflow-x: hidden;
  position: relative;
  overflow-y: hidden;
}
.airDropTopImg {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.airDropCloseIcon {
  position: absolute;
  top: 2%;
  right: 2%;
  background-color: rgb(255, 255, 255);
  color: black;
  width: 100px;
  text-align: center;
  border-radius: 13px;
  padding: 2px 0px;
  font-size: 13px;
  font-weight: 600;
}
.airDrop_Card_section {
  margin: 10px;
}

.airDropTapCreditCard {
  display: flex;
  background: rgba(255, 255, 255, 1);
  height: 105px;
  border-radius: 10px;
  padding: 0px;
}
.cardImgBox {
  background-color: green;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.cardImg {
  height: 100%;
  width: 100%;
  text-align: center;
}
.cardText {
  margin-left: 5px;
  padding-top: 5px;
}
.airDropPepeCard {
  display: flex;
  background: rgba(255, 255, 255, 1);
  height: 100px;
  border-radius: 10px;
  padding: 0px;
}
.airDrop_Text_section {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
}
.middleDiv {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
}
/* ============= */
.airdrop-container {
  margin: 20px auto;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 47vh;
  overflow-y: scroll;
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: left;
  color: #ffffff;
}

.airdrop-steps {
  list-style: decimal inside;
  padding-left: 20px;
}

.airdrop-steps li {
  margin-bottom: 20px;
  line-height: 1.6;
}

.airdrop-steps li strong {
  color: #f1f1f1;
  font-weight: 700;
}

.airdrop-steps ul {
  list-style: disc inside;
  padding-left: 20px;
  margin-top: 10px;
}

.airdrop-steps ul li {
  margin-bottom: 5px;
  color: #d1d1d1;
}

.note {
  font-style: italic;
  color: #b3b3b3;
  margin-top: 10px;
}

code {
  background-color: #3a3d42;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 0.9rem;
}
