.gameImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.openGameButton {
  padding: 10px 20px;
  border-radius: 15px;
  font-weight: bold;
  font-size: 18px;
  color: #c9c9c9;
  background-color: #bfa75d;
  outline: none;
  border: none;
}
