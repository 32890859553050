@import url("https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* ============css */
.miniGame_2_MainDiv {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-image: url("../../../media/v3_minigame_bg.png");
  background-size: cover;
  background-position: center;
  padding: 0;
  display: flex;
  justify-content: center;
  position: relative;
  font-family: "Roboto", sans-serif;
  /* margin-bottom: 3rem !important; */
}

.title_minigame {
  background: linear-gradient(to right, #bfa75d, #938539f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-size: 25px;
  font-weight: 800;
  font-family: "Roboto", sans-serif;
}

.subTitle_minigame {
  background: linear-gradient(to right, #bfa75d, #938539f7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-size: 20px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.bottom_of_miniGame {
  text-align: left;
}
.bottomTitle {
  font-size: 16px;
  font-style: italic;
  color: #c9c9c9;
  font-weight: bold;
}
.bottomSubtitle {
  font-size: 15px;

  color: #c9c9c9;
  font-weight: bold;
}

.winningBottom {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px auto;
}

/* =============== */
.miniGame_2_ContentWrapper {
  width: 100%;
  margin: 15px 15px;
}
.topButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topButton button {
  font-size: 18px;
  color: #fff000;
  font-weight: 600;
  text-align: center;
  background: #3f0da5;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 10px;
  font-family: "Poetsen One";
}
.minigame_2_ImgSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.logoImg_2_Section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
}

.TopImg_2_Section {
  width: 266px;
  height: 200px;
  /* background-color: #6531c1; */
  border-radius: 50%;
}
.mini_game_2_logo {
  width: 266px;
  height: 200px;
}

.game_2_CreditBox {
  background-color: #1e0256;
  color: #fff000;
  padding: 3px;
  margin-top: 50px;
  border-radius: 8px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.coinImage {
  width: 55px;
  height: 55px;
}
.creditTitle {
  font-family: "Poetsen One";
  font-weight: 400;
  font-size: 14px;
  color: white;
  margin-top: 8px;
}
.game_2_amount {
  font-size: 25px;
  font-weight: 600;
  font-family: "Oswald", sans-serif !important;
  color: white;
  margin: 0;
  margin-top: -5px;
  padding: 0;
}
.numberBoxMain {
  height: 345px;
  width: 100%;
  margin-top: 10%;
  padding-bottom: 2rem;
  position: relative;
}

.numberBox_2 {
  border: 2px solid #d78108;
  width: 100%;
  height: auto;
  position: relative; /* Changed to relative to position the pseudo-element correctly */
  border-radius: 30px;
  overflow: hidden; /* Ensures content doesn't overflow the border-radius */
}

.numberBox_2::before {
  border: 2px solid #d78108;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../../../media/v3_minigame_bg.png");
  background-size: cover;
  background-blend-mode: overlay;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(3px); /* Apply blur effect to the image */
  z-index: 0; /* Ensure the pseudo-element is behind other content */
}

.numberBox_2 > * {
  position: relative; /* Ensure content in the div is above the pseudo-element */
  z-index: 1;
}
.outsideBox {
  width: 99%;
  height: 100%;
}

.divider {
  width: 100%;
  height: 5px;
  margin: 8px 0px;
  background: linear-gradient(to right, white, transparent);
  border: none;
}

.NumberContentBox {
  padding: 8px; /* Space inside the box */
  position: relative;
  /* overflow: hidden; Prevent overflow of pseudo-element */
}

.NumberContentBox::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 8px dotted yellow;
  border-radius: 20.1px;
  z-index: 1;
  pointer-events: none;
  border-width: 8px;
  border-style: dotted;
  -webkit-border-radius: 20px !important;
  animation: smoothLightBlinking 1.6s infinite;
}

@keyframes smoothLightBlinking {
  0%,
  100% {
    border-color: yellow; /* Lights fully on */
    opacity: 1; /* Full brightness */
  }
  40%,
  60% {
    border-color: yellow; /* Lights off */
    opacity: 0; /* Fully off */
  }
}
.onlyBorder {
  /* Outer solid border */
  border-radius: 20px; /* Match the border radius */
  position: relative; /* Ensure stacking context */
  z-index: 0; /* Keep it below the NumberContentBox */
  /* border-image: linear-gradient(90deg, #651fff, #3d5afe) 1 !important; */
  box-sizing: border-box;
  /* overflow: hidden; */
}

.tagLine {
  font-family: "Poetsen One";
  font-size: 20px;
  font-weight: 600;
  color: white;
  margin-bottom: 5px;
}

.mainCoinBox {
  margin-top: 3%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 10px; /* Space between the boxes */
  padding: 10px; /* Ensure space from the border */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.box {
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.boxImg {
  /* max-width: 125%;
  max-height: 100%; */
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: relative;
}

.whatSignBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #6966662a;
  position: absolute;
  z-index: 999;
}
.whatSign {
  position: absolute;
  width: 45px;
  height: 45px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gameCoinSilver .gameGoldCoin {
  position: absolute;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.bottomSection {
  /* background: #050505f6; */
  height: 375px;
  width: 100%;
  z-index: 9999;
  position: absolute;
  top: 62%;
  left: 0;
  bottom: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.bottomSection h1 {
  color: white;
}

.game_2_CreditBox {
  background-color: #1e0256;
  color: #fff000;
  padding: 10px;
  margin-top: 30px;
  border-radius: 3px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.game_2_coinImage {
  width: 55px;
  height: 55px;
}
.game_2_creditTitle {
  font-family: "Poetsen One";
  font-weight: 400;
  font-size: 14px;
  margin-top: 8px;
  color: white;
}
.game_2_amount {
  font-size: 25px;
  font-weight: 600;
  font-family: "Poetsen One";
  color: white;
  margin-top: -8px !important;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}
.game_2_confeti {
  z-index: 10000000;
}

/* mdal------- */
.completeModal {
  width: 100%;
  height: 400px;
  background: rgb(33, 17, 52);
  background: linear-gradient(
    348deg,
    rgba(33, 17, 52, 1) 48%,
    rgba(34, 18, 55, 1) 63%
  );
  position: fixed;
  z-index: 99999;
  left: 0;
  bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.completeModal.show {
  transform: translateY(0);
  opacity: 1;
}

.completeModal.hide {
  transform: translateY(100%);
  opacity: 0;
}

.gameModalTop {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
}

.closeIconBox {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  cursor: pointer;
}

.gameCloseIcon {
  color: black;
  font-size: 12px;
  text-align: center;
  font-family: "Poetsen One";
}
.gameCongoModalContent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
}
.congoMsgImg {
  width: 160px;
  height: 90px;
}
.gameCongoTitle {
  font-size: 25px;
  color: white;
  font-family: "Poetsen One";
  margin: 0;
  padding: 0;
}
.coinImgParent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.congoCoinBox {
  width: 150px;
  height: 137px;
  background-image: url("../../../media/game-congo-bg.png");
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}
.gameCongoCoin {
  width: 80px;
  height: 80px;
}
.claimButton {
  outline: none;
  border: none;
  background-color: #fff000;
  text-align: center;
  font-size: 20px;
  color: #7800bb;
  font-family: "Poetsen One";
  border-radius: 12px;
  padding: 15px;
  margin-top: 10px;
}

.loader {
  color: white !important;
  font-size: 18px;
  font-family: "Poetsen One";
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 429px) {
  .miniGame_2_ContentWrapper {
    margin: 15px 15px;
  }
  .TopImg_2_Section {
    width: 266px;
    height: 200px;
  }
  .mini_game_2_logo {
    width: 100%;
    height: 100%;
  }
  .minigame_2_ImgSection {
    margin-top: -1%;
  }
  .creditCoinMain {
    margin-top: -3%;
  }
  .topButton button {
    font-size: 14px;
  }
  .numberBox_2 {
    margin-top: -2%;
  }
  .numberBoxMain {
    margin-top: 7%;
    height: 335px;
  }
  /* .mainCoinBox {
    gap: 4px;
  } */
  /* .box {
    height: 60px;
    width: 60px;
  } */
  .numberBox_2 {
    margin-bottom: 0rem;
  }
}

/* Larger devices (general fallback) */
@media only screen and (max-width: 429px) {
  .completeModal {
    height: 300px !important;
  }
  .congoMsgImg {
    height: 60px;
    width: 130px;
  }
  .gameCongoTitle {
    font-size: 20px;
  }
  .congoCoinBox {
    width: 100px;
    height: 85px;
  }
  .gameCongoCoin {
    height: 50px;
    width: 50px;
  }
  .claimButton {
    font-size: 16px;
  }
}
