.video-background {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

#background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.enter-background-gif {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.316);
  background: url("../../media/v3_loadingScreen3.png");
  /* background: url("../../media/loadingBg.jpg"); */
  background-position: center;
  background-size: cover;
}

@supports (-webkit-overflow-scrolling: touch) {
  .enter-background-gif {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.316);
    z-index: -2;
    background: url("../../media/v3_loadingScreen3.png");
    background-position: center;
    background-size: cover;
  }
}

.loadingDiv_Text {
  text-align: center;
  color: white;
}

.color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.201);
  /* Adjust the color and opacity here */
  z-index: -1;
}

.content {
  position: relative;
  /* z-index: 1; */
  color: white;
  text-align: center;
  padding: 20px;
  width: 100%;
}

.tapToEarnMainDiv {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  justify-content: space-between;
  z-index: 1000;
}

.tapToEarnMainDiv .hand-icon {
  font-size: 40px;
  margin: 0;
}

.tapToEarnMainDiv .tap-text {
  font-size: 17px;
  font-weight: 800;
  color: white;
  margin-top: 28px;
}

.loading-enter-btn {
  border: 1px solid rgba(255, 255, 255, 0.839);
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  cursor: pointer;
  z-index: 999;
}

.loading-enter-btn p {
  font-size: 28px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.839);
  letter-spacing: 3px;
}

.image-container {
  display: inline-block;
  transition: transform 0.2s ease, background-color 0.2s ease;
  position: fixed;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  height: 50vw;
  width: 50vw;
  max-width: 220px;
  max-height: 220px;
  border-radius: 50%;
}

.image-container.tapped {
  transform: scale(0.9);
  background-color: rgba(0, 0, 255, 0);
  /* Semi-transparent blue background */
}

.tappable-image {
  display: block;
  width: 100%;
  height: auto;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.beacon-loading {
  border-radius: 99;
  animation: shine 2s ease-in-out infinite;
}

.tapdiv-loading {
  z-index: 999;
  /* background: linear-gradient(#577cff, #2731eb, #3f1cc8, #0e338d); */
  height: 50vw;
  width: 50vw;
  max-width: 240px;
  max-height: 240px;
  border-radius: 50%;
  position: fixed;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  transition: transform 0.5s ease;
}

.tapdiv-loading:active {
  /* background: linear-gradient(#3f1cc8, #0e338d, #577cff, #2731eb); */
}

.allIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 43%;
  transform: translate(-50%, -50%);
}

.loadingIconDiv {
  background: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 5px;
}

.loadingImgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingIconDiv img {
  width: 50px;
  height: 50px;
  display: flex;
}

.loadingIcon {
  width: 12px;
}

.loaderDiv {
  position: fixed;
  bottom: 4%;
  width: 100%;
  z-index: 999;
  margin-bottom: 15px;
}

/* .loaderDiv img {
  width: 35px;
  height: 35px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  animation: spin 1s linear infinite;
} */

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

.loaderDiv p {
  color: white;
  font-size: 15px;
  margin: 0;
  text-align: center;
}

.loadingDivParent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.loadingMainDiv {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 9px;
  padding-inline: 3px;
  background-color: rgb(0, 0, 0);
  height: 14px;
  width: 250px;
  border-radius: 99px;
  position: relative;
}

.loadingDiv {
  height: 12px;
  border-radius: 99px;
  position: relative;
  z-index: 1;
  transition: width 0.4s ease; /* Smooth transition as the progress bar grows */
}

.whiteCircle {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  background-color: rgb(110, 61, 20);
  border-radius: 50%;
  z-index: 2;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* Optional shadow */
  display: flex;
  justify-content: center;
  align-items: center;
}

.blackCircle {
  width: 6px; /* Smaller size for inner black circle */
  height: 6px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
}
@media only screen and (max-width: 375px) and (max-height: 667px) {
  .enter-background-gif {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.316);
    background: url("../../media/v3_loadingScreen3.png");
    /* background: url("../../media/loadingBg.jpg"); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
