@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.frogbar_over_container {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
  position: relative;
  font-family: "Roboto";
}
.count_close {
  text-align: right;
  margin: 0px;
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
}
.content_count_down {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0px;
}
.count_down_Title {
  font-size: 45px;
  color: blueviolet;
  font-weight: 800;
  text-align: center;
}
.count_down_sub_title {
  margin-top: 5px;
  font-size: 20px;
  font-weight: 600;
}
.count_down {
  /* position: absolute; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  /* bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.count_title {
  font-size: 20px;
  font-size: 800;
  text-align: center;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.count {
  color: black;
  background-color: rgba(255, 252, 252, 1);
  border-radius: 10px;
  height: 40px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  font-size: 25px;
  font-weight: 800;
}
.listing_soon {
  position: absolute;
  top: 46%;
  background-color: white;
  padding: 10px;
  width: 100%;
  overflow: hidden;
}

.listing_soon_text {
  font-size: 20px;
  font-weight: 800;
  text-transform: uppercase;
  color: black;

  letter-spacing: 2px;
}
.count_buy_now {
  position: absolute;
  bottom: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.count_buy_now_text {
  font-weight: 600;
  font-size: 25px;
  color: white;
  margin-bottom: 5px;
}
.count_buy_now_button {
  background: linear-gradient(
    to right,
    rgba(4, 174, 24, 0.8),
    rgba(0, 251, 88, 0.78),
    rgba(4, 174, 24, 0.8),
    rgba(0, 251, 88, 0.78)
  );
  font-weight: 800;
  font-size: 20px;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  border: none;
  width: 90vw;
  color: white;
  height: 50px;
}
@media only screen and (max-width: 320px) {
  /* Portrait */
  .count_down_Title {
    font-size: 30px;
  }
  .count_buy_now_text {
    font-size: 15px;
  }
  .listing_soon {
    top: 60%;
  }
}
