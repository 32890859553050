@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.privateSaleContainer {
  background: rgba(29, 28, 29, 1);
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  position: relative;
}

.privetSaleTopsection {
  /* height: 37vh; */
  width: 100%;
  /* background: red; */
  z-index: 1;
  background-image: url("../../../media/v3_presaleBG.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 1rem;
}

.coffeeModalBlueBox {
  background-color: #1b1b30;
  margin: 8px auto;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 9px;
  border-left: 5px solid #36b6cb;
}

/* ============ */
.privetSaleProfileBar {
  margin: 8px 0px;
}

.privetSaleTop_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.titleBox {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 5px;
  font-family: "Roboto", sans-serif;
  text-align: center;
}

.title {
  font-size: 22px;
  font-weight: 800;
  letter-spacing: 2px;
  margin-left: 38px;
}

.learnMore {
  border: 2px solid rgba(191, 167, 93, 1);
  padding: 5px 10px;
  color: white;
  border-radius: 15px;
  font-size: 12px;
}

.privateSaleText {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 2px;
  color: #bfa75d;
  margin: 5px 0px;
}

.privetSaleCount {
  font-size: 35px;
  font-weight: 800;
  letter-spacing: 2px;
  color: white;
  /* margin-bottom: 5px; */
  margin-top: -7px;
}

.privateSaleCurrent {
  width: 50vw;
  height: 50px;
  margin-top: 20px;
  background: linear-gradient(90deg, #141414 0%, #414141 99.76%, #7a7a7a 100%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid gold;
}

.box1,
.box2,
.box3,
.box4 {
  text-align: center;
}

.timer {
  font-size: 15px;
  color: gray;
  letter-spacing: 1px;
}

.rate {
  color: green;
  display: flex;
  align-items: center;
  justify-content: center;
}

.privetSaleTonBox {
  display: flex;
  width: 90vw;
  gap: 10px;
  margin-top: 40px;
}

.SolanaBox {
  width: 100%;
  height: 110px;
  position: relative;
  border: 1px solid gold;
  border-radius: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  padding: 8px;
}

.tonBox {
  width: 100%;
  height: 110px;
  border: 1px solid gold;
  border-radius: 15px;
  position: relative;
  color: white;
  padding: 8px;
}

.holderBox {
  width: 96%;
  height: 110px;
  background: rgba(29, 28, 29, 1);
  border: 1px solid gold;
  border-radius: 15px;
  position: relative;
  color: white;
  padding: 8px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solanaIcon {
  height: 80px;
  width: 80px;
  position: absolute;
  top: -40px;
  left: -8px;
  background: transparent;
}

.tonImg {
  height: 80px;
  width: 80px;
  position: absolute;
  top: -40px;
  right: -12px;
  background: transparent;
}

.joinButton {
  height: 40px;
  width: 70px;
  border-radius: 12px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -20px;
  right: 15px;
  color: white;
  background: rgba(34, 34, 34, 1);
  border: 1px solid gold;
}

.holderImg {
  width: 100px;
  height: 100px;
}

.Ton_and_Tg {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.Ton_and_Tg {
  margin: 0px 10px;
  height: 45px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  /* padding: 0px 8px; */
  background: rgba(29, 28, 29, 1);
  border: 1px solid whitesmoke;
}

.TonPackage {
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.activePackageButton {
  background: rgba(49, 49, 49, 0.3);
  /* More transparency to see the background */
  padding: 7px;
  border-radius: 25px;
  backdrop-filter: blur(10px);
  /* Blurs the background behind the button */
  -webkit-backdrop-filter: blur(10px);
  /* Safari support */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  /* Optional shadow for depth */
  border: 1px solid rgba(255, 255, 255, 0.2);
  /* Optional border for contrast */
}

.tgPackage {
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

/* .packageTable {
  height: 32vh;
  overflow-y: scroll;
} */

/* ============== */
.historySection {
  margin: 0px 10px;
  height: 45px;
  margin-top: 25px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0px 8px;
  background: rgba(29, 28, 29, 1);
  border: 1px solid whitesmoke;
}

.purchase {
  color: white;
  padding: 5px;
  text-align: center;
  width: 95%;
  border-radius: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  cursor: pointer;
}

.active {
  background: rgba(49, 49, 49, 1);
  /* padding: 1px; */
}

.historyTableSection {
  margin-bottom: 150px;
}

.packageSectionTable {
  margin-bottom: 10px;
}

.tableFirstRow {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  color: white;
  margin: 5px auto;
  border-bottom: 2px solid gray;
  text-align: center;
}

.tableFirstRow table td {
  font-size: 12px;
  border: none;
  border-bottom: 1px solid white;
  padding: 10px 0;
  text-align: left;
}

.tableFirstRow table th {
  text-align: left;
  border-bottom: 1px solid white;
  padding-bottom: 5px;
  font-size: 11px;
}

.tableFirstRow table tr {
  font-size: 14px;
}

.packageRow {
  width: 95vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  color: white;
  margin: 5px auto;
  text-align: center;
}

.confirmCoffeeButton {
  background: #bfa75d;
  color: #e7e1e1;
  padding: 10px 20px;
  border-radius: 10px;
  outline: none;
  border: none;
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 13px;
}

.coffeeImgSection {
  margin-top: -7px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.coffeeTick {
  position: absolute;
  top: -12%;
  left: 50%;
  height: 55px;
  width: 50px;
}

.buyCoffeeContent {
  margin: 3px 0 0px 19px;
}

.buyCoffeeText {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 400;
  color: #e7e1e1;
  text-align: center;
}

.coffeeAmount {
  text-align: center;
  color: #e7e1e1;
  font-size: 30px;
  font-weight: 800;
}

.featuresSection {
  width: 100%;
  margin: 5px auto;
  /* overflow-x: hidden; */
}

.coffeeFeaturesBoxes {
  background-color: #171717;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  justify-content: center;
  padding: 10px;
  border-radius: 15px;
}

.coffeeFeaturesBox {
  background: #212121;
  width: 46px;
  height: 80px;

  border-radius: 10px;
  text-align: center;
  padding: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid #a7a5a5;
}

.coffeeBoxTitle {
  font-size: 9px;
  line-height: 13px;
}
.hotelName {
  padding-top: 0px;
}

.coffeeBoxBottom {
  margin-top: -7px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 8px;
  border-bottom: 1px solid gray;
}
/* Button styling */

@media only screen and (max-width: 375px) and (max-height: 767px) {
  .title {
    font-size: 22px;
  }

  .privateSaleText {
    font-size: 20px;
  }

  .privetSaleCount {
    font-size: 25px;
  }

  .historyTableSection {
    margin-bottom: 100px;
  }

  .coffeeFeaturesBoxes {
    background-color: #141414;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
  }
  /* .privetSaleTopsection {
    height: 45vh;
  } */
}

@media only screen and (max-width: 425px) and (max-height: 723px) {
}
