@import url("https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap");

.energyPopup {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 430px;
  /* background: rgb(87, 189, 78);
  background: radial-gradient(
    circle,
    rgba(87, 189, 78, 1) -25%,
    rgba(34, 18, 55, 1) 44%,
    rgba(34, 18, 55, 1) 100%
  ); */
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  z-index: 999999;
}
.energyPopupContent {
  padding: 20px;
}
.energyPopupTop {
  display: flex;
  justify-content: space-between;
}
.energyPopupDivider {
  width: 100%;
  height: 5px;
  margin: 15px 0px;
  background: linear-gradient(to right, white, transparent);
  border: none;
}
.energyPopupTitle {
  font-size: 18px;
  font-weight: 400;
  font-family: "Poetsen One";
  color: #fff;
}
.energyContentBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
}
.energyImg {
  width: 76px;
  height: 176px;
}
.enegeryBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.enegeryBottomBox {
  color: #800faf;
  background: #fff000;
  text-align: center;
  margin-top: 5px;
  border-radius: 5px;
  font-family: "Poetsen One";
  padding: 10px;
  font-weight: 400;
  font-size: 15px;
}

.enegeryBottomBox.recharging {
  position: relative;
  overflow: hidden;
}

.enegeryBottomBox.recharging::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: recharging 1.5s infinite;
}

@keyframes recharging {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.infoEnergy {
  font-family: "Poetsen One";
  color: white;
  font-weight: 400;
}
.energyHelth {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  font-family: "Poetsen One";
  color: white;
}
