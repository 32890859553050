@import url("https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap");
.RulesModal {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 430px;
  background: #1c0e3a;
  /* background: radial-gradient(
    circle,
    hsla(247, 60%, 21%, 1) 0%,
    hsla(0, 0%, 2%, 1) 100%
  ); */
  width: 100%;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  z-index: 999999;
}
.customPopupContent {
  padding: 20px;
}
.customModalTop {
  display: flex;
  justify-content: space-between;
}
.customModalDivider {
  width: 100%;
  height: 5px;
  margin: 15px 0px;
  background: linear-gradient(to right, white, transparent);
  border: none;
}
.customPopupTitle {
  font-size: 18px;
  font-weight: 400;
  font-family: "Poetsen One";
  color: #fff;
}
.closeIcon {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  cursor: pointer;
}
.closeIcon {
  color: black;
  font-size: 12px;
  text-align: center;
  font-family: "Poetsen One";
}
.customModalContentBox {
  background: #150a2c;
  padding: 8px;
  width: 100%;
  border: 0.5px solid #3f3956;
  border-radius: 5px;
  margin: 12px -8px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.customModalContentBox p {
  color: white;
  font-weight: 400;
  font-family: "Poetsen One";
  font-size: 14px;
  line-height: 18.6px;
}

.customModalBottom {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px; /* Space between the boxes */
  /* Ensure space from the border */
  box-sizing: border-box;
  font-family: "Poetsen One";
}
.bottomBox {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #800faf;
  background: #fff000;
  text-align: center;
  margin-top: 5px;
  border-radius: 5px;
  font-family: "Poetsen One";
  padding: 5px;
  font-weight: 400;
  font-size: 12px;
  height: 30px;
}
