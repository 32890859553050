@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.taskContainer {
  background-color: rgba(29, 28, 29, 1);
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  position: relative;
  font-family: "Roboto", sans-serif;
}

.overlay {
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.taskContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  padding-bottom: 100px;
  top: -4rem;
}
.pfBarr2 {
  margin-top: -37px;
  width: 93vw;
}

.task_AmountSection {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: -2px;
}
.task_amount {
  color: white;
  font-size: 35px;
  letter-spacing: 1px;
  font-weight: 800;
}
.socialAccouts3rx {
  display: flex;
  flex-direction: column;
  width: 95vw;
  margin-top: 140px;
}

.task_Card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(51, 51, 51, 0.25);
  box-shadow: 0 4px 8px #00000040; /* Drop shadow with 25% opacity */
  padding: 10px;
  border-radius: 12px;
  gap: 6px;
  line-height: 20px;
  margin-top: 4px;
}
.borderCard {
  border: 1px solid #c9c9c9;
}

.taskCardImg {
  /* width: 60px; */
  height: 55px;
  object-fit: cover;
}
.task_text {
  color: #c9c9c9;
  width: 45%;
}
.text_1stSpan {
  text-transform: uppercase;
  font-size: 13px;
  color: #c9c9c9;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.task_Reward {
  text-align: center;
  font-size: 10px;
  font-weight: bolder;
  color: #c9c9c9;
}

.SocialAccountBox {
  border-radius: 9px;
  background: rgba(101, 76, 192, 0.212);
  color: #ffffff4b;
  width: 93vw;
  margin: 8px auto;
}

.taskSwitchTab {
  color: white;
  font-size: 20px;
  padding: 5px 10px;
  border: 15px;
  text-align: center;
  border-radius: 15px;
  width: 100%;
  cursor: pointer;
}
.activeSwitch {
  background: rgba(96, 96, 96, 1);
  width: 100%;
}
