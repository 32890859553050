.body {
  background: #1d1c1d;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  position: relative;
}
.overlay {
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  /* overflow-y: scroll; */
}
.overlay::-webkit-scrollbar {
  display: none;
}

.statContent {
  position: absolute;
  /* margin-top: 2rem; */
  /* padding-top: 11rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  /* top: -1rem; */
  overflow-y: scroll;
}
.pfBar {
  width: 93vw;
  margin: 0;
}

.dataBox {
  width: 93vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 1rem 0;
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  background-color: rgba(255, 255, 255, 0.035);
  border: 1px solid rgba(255, 255, 255, 0.041);
  backdrop-filter: blur(5px);
  border-radius: 16px;
  padding: 5px 0;
}
.columnBox {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.dataColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}

.head {
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  /* text-decoration:underline dashed;
    text-underline-offset: 8px; */
}
.count {
  margin-top: 5px;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  letter-spacing: 2px;
}

.competeTime {
  width: 93vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0.5rem 0;
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  background-color: rgba(255, 255, 255, 0.035);
  border: 1px solid rgba(255, 255, 255, 0.041);
  backdrop-filter: blur(5px);
  border-radius: 16px;
  padding: 6px 0;
}

.compBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.medalIcon {
  margin-top: 7px;
  width: 18px;
}
.compText {
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  margin-left: 5px;
}

.toggleBox {
  margin-top: 0px;
  width: 93vw;
  margin-bottom: 80px;
}

.toggleButtons {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.305);
  border-radius: 15px;
}

.toggleButton {
  flex: 1;
  text-align: center;
  padding: 10px;
  color: white;
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  background-color: inherit;
  border: none;
  outline: none;
}

.toggleButtons {
  display: flex;
  justify-content: space-between;
  border: 1px solid white;
  margin: 10px 0px;
}

.toggleButton {
  flex: 1;
  text-align: center;
  padding: 10px;
  color: #8a8a8a;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  background-color: inherit;
  border: none;
  outline: none;
}

.toggleButton.active {
  background: #606060;
  width: 200px;
  border-radius: 13px;
  color: #c9c9c9;
}

.statsList {
  margin-top: 20px;
  overflow-y: scroll;
  max-height: 45vh;
}
.yourRankBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
  width: 90vw;
  border-bottom: 2px solid gray;
  margin-bottom: 14px;
}
.yourRank {
  color: #c9c9c9;
  font-size: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  margin-bottom: 2px;
  margin-right: 10px;
}
.yourRank span {
  color: rgba(255, 236, 127, 1);
  font-size: 12px;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
}
.yourCreditsBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 6px;
  width: 100%;
}
.yourCreditsIcon {
  width: 28px;
  margin-right: 5px;
}
.yourCreditCount {
  color: rgba(247, 247, 247, 1);
  font-size: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  padding-bottom: 4px;
}

.statsItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgba(137, 130, 130, 0.42);
}

.yourRankDiv .statsItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 5px; */
  margin-bottom: 10px;
}
.userIcon {
  width: 30px;
  height: 30px;
}
.profilePic {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}
.ppl {
  display: flex;
  align-items: center;
}
.ppRank {
  width: 40px;
  height: 40px;
}
.ppLevel {
  width: 30px;
  height: 30px;
  margin-left: -20px;
}
.profilepic {
  position: absolute;
  top: 16.7px;
  left: 27px;
  width: 46px;
  height: 45px;
  position: absolute;
  border-radius: 50%;
}
.frogImg {
  width: 46px;
  height: 45px;
  position: absolute;
  border-radius: 50%;
}

.statsItem .userName {
  flex: 1;
  margin-left: 10px;
  color: white;
  font-size: 15px;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  display: flex;
  align-items: baseline;
  justify-content: start;
}

.rankIcon {
  margin-right: 8px;
  margin-top: 3px;
  height: 25px;
  width: 18px;
}

.statsItem .userScore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  letter-spacing: 2px;
  color: rgba(253, 178, 56, 1);
}

.statsItem .userIcon {
  width: 32px;
  height: 32px;
}

.bottomNav {
  width: 90%;
  margin-top: 2rem;
}

.checkIcon {
  border-radius: 100%;
  width: 15px;
  margin: 5px;
  top: 2px;
  position: relative;
}

@media (max-width: 375px) and (max-height: 667) {
  .statsList {
    max-height: 30vh;
  }
}
