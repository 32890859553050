@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.main-game {
  position: relative;
  height: 100vh;
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-image: url("../../media/v3BgImg.png"); */
  background-position: center;
  background-size: contain;
}
/* ========================================= */

.newtap {
  /* z-index: -1; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  object-fit: contain;
}

.TapingImg {
  object-fit: contain;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease; /* Smooth transition */
}

.newtap.zoomed .TapingImg {
  transform: scale(0.9); /* Zoom in effect */
}

/* Optional zoom-out reset to normal size */
.newtap .TapingImg {
  transform: scale(1); /* Normal size */
}

/* ======================================================== */

/* .confeti {
  position: absolute;
  z-index: 101010101010110101010 !important;
} */

.calenderModalpfx {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
}
.walletModalpfx {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
}
.color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.8;
}
.content {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100vw;
  /* overflow-y: scroll; */
  justify-content: space-between;
  top: -18px;
}
.playFixedDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.walletDiv {
  display: none;
  justify-content: space-between;
  width: 100%;
  max-width: 92%;
  margin-top: 15px;
  margin-inline: auto;
  background: #100f2f;
  border: 1px solid r;
  color: white;
  border-radius: 99px;
  border: 1px solid #8272dd;
  position: relative;
}

.walletDiv p {
  margin-left: 20px;
  font-size: 16px;
}

.walletDiv p span {
  font-size: 16px;
  font-weight: 700;
}

.walletDiv div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
  cursor: pointer;
}

.walletDiv div p {
  font-size: 15px;
  font-weight: 700;
}

.connect-wallet-container {
  /* z-index: 1300; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  background: rgba(144, 143, 143, 0.273);
  backdrop-filter: blur(5px);
}
.closeIcon_ax {
  font-size: 20px;
}
.connect-wallet-content {
  background: rgba(33, 16, 46, 0.9); /* Match the color from your screenshot */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: white;
  /* width: 90%; */
  width: 93vw;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  /* bottom: 0; */
}
.wallet-content {
  /* margin-top: 2rem; */
  /* margin: 0 auto; */
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
}
.wallet-content p {
  margin-top: 10px;
  text-align: center;
}
.wallet-content h1 {
  margin-bottom: 0;
  text-align: center;
  line-height: 35px;
}

.connIcon2 {
  width: 90px;
}

.connect-wallet-button {
  background-color: rgba(255, 255, 255, 0.071); /* Button color */
  backdrop-filter: blur(5px);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 12px 0 1.5rem 0;
  border-radius: 3rem;
}

.connect-wallet-button:hover {
  background-color: #333;
}

.welcome-header {
  width: 92%;
  margin: 0 auto;
  color: white;
}

.welcome-header p {
  margin: 5px 0;
}

.anime {
  z-index: 1000;
  pointer-events: none;
  user-select: none;
  color: white;
  font-size: 30px !important;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  position: absolute;
  animation: visibility 2s ease-out forwards;
  /* animation:transform 3s; */
  opacity: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* @keyframes transform {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  0% {
    opacity: 1;
    transform: translate(-50%, -20%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -10%);
  }
} */

@keyframes visibility {
  from {
    opacity: 100%;
    transform: translateY(0);
  }

  to {
    opacity: 0%;
    transform: translateY(-300px);
  }
}

.tapdiv {
  z-index: 999;
  height: 64vw;
  width: 64vw;
  max-width: 270px;
  max-height: 270px;
  border-radius: 50%;
  position: fixed;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  transition: transform 0.5s ease;
}

.plus-one {
  position: absolute;
  top: 50%; /* Example positioning */
  left: 50%; /* Example positioning */
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  animation: flyUp 1s ease-out forwards;
  pointer-events: none; /* Prevent pointer events on the flying +1 */
}

@keyframes flyUp {
  0% {
    opacity: 1;
    transform: translateY(0) translateZ(0); /* Reset transform */
  }
  25% {
    opacity: 1;
    transform: translateY(-30px) translateZ(0); /* Move up higher */
  }
  100% {
    opacity: 0;
    transform: translateY(-100px) translateZ(0); /* Move further up and fade out */
  }
}

.tabImg {
  position: absolute;
  top: 53%;
  left: 50%;
  z-index: 999;
  height: 64vw;
  width: 64vw;
  max-width: 270px;
  max-height: 270px;
  border-radius: 50%;
}

@keyframes tapWave {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.beacon {
  /* margin-top: 10px; */
  border-radius: 99;
  animation: shine 2s ease-in-out infinite;
}

@keyframes shine {
  0%,
  20% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.49);
  }

  100% {
    box-shadow: 0px 0px 0px 50px rgba(63, 62, 62, 0);
  }
}

.welcomeBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin: 6px 0 3px 0;
}
.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 60%;
}
.lTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
}
.ppBox {
  display: flex;
  /* width: 100%; */
}
.profileContainer {
  position: relative;
}
.ppll {
  display: flex;
  top: 0;
}

.ppLevelll {
  width: 88px;
  height: 60px;
}
.profilepicc {
  position: absolute;
  top: 8.7px;
  left: 20.9px;
  border-radius: 50%;
}
.frogImggg {
  width: 46px;
  height: 45px;
  position: absolute;
  border-radius: 50%;
}
.welBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 5px;
}
.welText {
  font-size: 12px;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
}
.nameBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pepeName {
  color: #ffffff;
  font-size: 16px;
  margin: 0 4px 2px 0;
  font-family: "Oswald", sans-serif;
}
.levelIcon {
  width: 25px;
}
.lBottom {
  display: flex;
  align-items: center;
  margin: 6px 0 1px 0;
  max-width: 100%;
}
.levelText {
  color: rgba(255, 183, 44, 1);
  font-size: 10px;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(30px);
  border: 2px solid rgba(217, 217, 217, 0.1);
  border-radius: 3rem;
  padding: 1px 10px;
  margin-right: 5px;
  text-align: center;
}
.levelscore {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.9rem;
  margin-bottom: 1px;
}
.progressBox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background-color: rgba(209, 255, 210, 0.253);
  position: relative;
  height: 20px;
  border-radius: 3rem;
  position: relative;
}
.circle {
  position: absolute;
  background-color: rgba(168, 212, 170, 0.4);
  width: 17px;
  height: 17px;
  border-radius: 3rem;
  left: 2.2px;
  top: 1.7px;
  z-index: 10;
}
.greenBar {
  background: linear-gradient(269.84deg, #4cc820 2.96%, #048919 96.87%);
  position: absolute;
  /* width: 80%; */
  height: 17px;
  width: 17px;
  left: 2.2px;
  top: 1.7px;
  border-radius: 3rem;
}
.barRank {
  height: 100%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.rank {
  font-size: 8px;
  position: absolute;
  font-family: "Oswald", sans-serif;
  color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}
.rank span {
  color: rgba(255, 255, 255, 1);
  font-size: 8px;
  z-index: 2;
  font-family: "Oswald", sans-serif;
}
.starIcon {
  color: rgba(255, 255, 255, 0.5);
  font-size: 9px;
  margin: 2px;
}

.arrowIcon {
  margin-left: 5px;
  background-color: rgba(216, 117, 0, 1);
  backdrop-filter: blur(30px);
  border-radius: 3rem;
  border: 1px soli rgba(246, 5, 5, 0.1);
  padding: 3px;
  color: #ffffff;
  font-size: 15px;
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
}
.connectBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid white;
  backdrop-filter: blur(30px);
  padding: 1px 14px;
  border-radius: 3rem;
}
.connIcon {
  line-height: 0;
  margin: 4px 3px 3px 0;
  width: 10px;
  height: 8px;
}
.conn {
  align-items: center;
  font-size: 10px;
  font-weight: 700;
  color: #ffffff;
}
.credBox {
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.PEPECoinImg {
  width: 45px;
  margin-right: 4px;
  margin-top: 10px;
}
.mytapcredBox {
  margin-right: 15px;
}
.creds {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #ffffff;
}
.pepeTextt {
  font-size: 10px;
  letter-spacing: 2px;
  font-family: "Oswald", sans-serif;
  /* font-weight: 500; */
  width: 5rem;
}
.pepeCount {
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  line-height: 19px;
  font-family: "Oswald", sans-serif;
  letter-spacing: 3px;
}
.buyButton {
  margin-top: 4px;
  background-color: rgba(216, 117, 0, 1);
  border-radius: 3rem;
  padding: 0 20px;
  font-size: 10px;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  cursor: pointer;
  text-decoration: none;
}

.topCardDiv {
  color: white;
  border-radius: 8px;
  width: 100%;
  margin-top: -15px;
  padding: 5px 0;
  display: flex;
  justify-content: space-around;
  position: relative;
}
.card2 {
  margin-top: 13px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.creditCoinImg {
  height: 50px;
  width: 50px;
  margin-left: auto;
  margin-right: 5px;
}

.total-credit-number {
  font-size: 35px;
  font-weight: 700;
  margin: 0;
  line-height: 30px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
}

.pre-sale-price {
  font-size: 14px;
  margin: 0;
  width: 100%;
  font-weight: 800;
  margin-top: 14px;
}

.pre-sale-text {
  color: #ffec42;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  width: 100%;
  font-weight: 700;
  text-align: center;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../media/v3_iconSquare.png");
}

.red {
  width: 15px;
  height: 15px;
  background: red;
  position: absolute;
  z-index: 1;
  top: 9%;
  right: 10%;
  color: white;
  font-size: 10px;
  text-align: center;
}

.subIconImg {
  object-fit: cover;
}

.subButtonIconDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
  margin-left: 16px;
  padding: 10px 0px;
}

.subButtonIconDiv.open {
  margin-top: -20px;
  max-height: 500px; /* Adjust based on your content */
  opacity: 1;
  background: linear-gradient(
    270deg,
    rgba(158, 138, 131, 0.9) 0%,
    rgba(1, 1, 1, 0.9) 100%
  );
}

.subButtonIconDiv.closed {
  margin-top: -20px;
  max-height: 0;
  opacity: 0;
  background: linear-gradient(
    270deg,
    rgba(158, 138, 131, 0.9) 0%,
    rgba(1, 1, 1, 0.9) 100%
  );
}
/* ========== */

.statsIcon {
  position: relative;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.411);
  box-shadow: 0 0 20px rgba(207, 205, 205, 0.723),
    0 0 40px rgba(223, 223, 223, 0.715); /* Light glow effect */
  transition: box-shadow 0.3s ease;
}

/* .statsIcon:hover {
  box-shadow: 0 0 20px rgba(207, 205, 205, 0.723),
    0 0 40px rgba(223, 223, 223, 0.715); /* Stronger glow on hover */

/* ============ */
.CeoRankDiv {
  color: white;
  margin-left: 13px;
  margin-right: 13px;
  margin-bottom: 7.3rem;
  cursor: pointer;
  z-index: 10;
}
.ceoRankInfo {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.Playlevelscore {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1px;
  margin-top: 2px;
}
.PlayprogressBox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background: rgba(50, 54, 60, 1);
  position: relative;
  height: 20px;
  border-radius: 3rem;
  position: relative;
  margin-top: 5px;
  /* margin-left: -6px; */
}

/* .PlaygreenBar {
  background: linear-gradient(90deg, #ff7a00 0%, #ffa114 45%, #87c835 100%);
  position: absolute;
  height: 16px;
  width: 17px;
  left: 2.2px;
  top: 1.7px;
  border-radius: 3rem;
} */
.PlaygreenBar {
  background: linear-gradient(90deg, #ff7a00 0%, #ffa114 45%, #87c835 100%);
  position: absolute;
  height: 16px;
  width: 0%; /* Start with 0% width */
  left: 2.2px;
  top: 1.7px;
  border-radius: 3rem;
  transition: width 1.5s ease-in-out; /* Smooth animation of width change */
}
.barRank {
  height: 100%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.rank {
  font-size: 8px;
  position: absolute;
  font-family: "Oswald", sans-serif;
  color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}
.rank span {
  color: rgba(255, 255, 255, 1);
  font-size: 8px;
  z-index: 2;
  font-family: "Oswald", sans-serif;
}
.starIcon {
  color: rgba(255, 255, 255, 0.5);
  font-size: 9px;
  margin: 2px;
}

.arrowIcon {
  margin-left: 5px;
  backdrop-filter: blur(30px);
  border-radius: 3rem;
  border: 1px soli rgba(246, 5, 5, 0.1);
  padding: 3px;
  color: #ffffff;
  font-size: 15px;
}

/* ================= */

.paly-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  font-weight: 500;
  margin-bottom: 6rem;
}
.rank-data {
  width: 95%;
  display: flex;
  justify-content: space-between;
}
.energy-boost-box {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Qmark {
  margin-left: 6px;
}
.right-play-data {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.boost-button {
  color: white;
  padding: 3px 1px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 33px;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  margin-bottom: 2px;
}

@keyframes boxShadowPulse {
  0% {
    box-shadow: rgba(195, 47, 47, 0) 0px 0px 20px;
  }
  50% {
    box-shadow: rgb(169 56 194) 0px 0px 20px;
  }
  100% {
    box-shadow: rgba(195, 47, 47, 0) 0px 0px 20px;
  }
}

@keyframes pulseGlow {
  0% {
    box-shadow: 0 0 10px rgba(255, 255, 0, 0.5), 0 0 16px rgba(255, 255, 0, 0.3),
      0 0 30px rgba(255, 255, 0, 0.2);
  }
  50% {
    box-shadow: 0 0 16px rgba(255, 255, 0, 0.8), 0 0 30px rgba(255, 255, 0, 0.5),
      0 0 40px rgba(255, 255, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 255, 0, 0.5), 0 0 16px rgba(255, 255, 0, 0.3),
      0 0 30px rgba(255, 255, 0, 0.2);
  }
}

@media only screen and (max-width: 375px) {
  .CeoRankDiv {
    margin-bottom: 6.8rem;
  }
  .topCardDiv {
    margin-top: -18px;
  }
  .newtap {
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
  }
}
@media only screen and (width: 425px) {
  .CeoRankDiv {
    margin-bottom: 6.8rem;
  }
  .topCardDiv {
    margin-top: -18px;
  }
}
