@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  background-color: #181818;
  color: white;
  font-family: Arial, sans-serif;
}

.club-container {
  display: "flex";
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70vh;
  background-color: #222222 !important;
  border-radius: 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  text-align: center;
  overflow: hidden;
}

.club-cover-div {
  position: absolute;
  width: 100%;
  height: 73%;
  top: 0;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
}

.button-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  border: 1px solid #c9c9c9;
  border-radius: 50px;
  width: 90%;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent;
  color: #c9c9c9;
  height: 3rem;
  border: none;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.5s ease, color 0.5s ease, transform 0.3s ease;
}
.buttons:focus {
  background-color: transparent;
}

.playProgressClub {
  margin-top: 0;
}

.playGreenClub {
  top: 0;
}

.buttons2 {
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent;
  color: #c9c9c9;
  height: 2rem;
  border: none;
  width: 7rem;
  margin: 0 2.5rem;
  cursor: pointer;
  transition: background-color 0.5s ease, color 0.5s ease, transform 0.3s ease;
}

.active2 {
  color: #bfa75d;

  border-bottom: 2px solid #bfa75d;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 51vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 3rem;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: calc(50% - 20px);
  /* height: 11rem; */
  flex-shrink: 0;
  background-color: #2d2b2b;
  position: relative;
}

.active {
  background-color: #282828;
  color: #c9c9c9;
  border-radius: 50px;
  padding: 10px;
}
.active:focus {
  background-color: #282828;
}
/* ================level up popup children compoent==== */
.levelUpTitle {
  font-size: 18px;
  font-weight: 800;
  color: #c9c9c9;
  letter-spacing: 1px;
  text-align: center;
  font-family: "Roboto", sans-serif;
}
.levelUpContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
/* .levelUpImgBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid gold;
  border-radius: 10px;
  padding: 10px;
  width: 150px;
  height: 200px;
} */
.levelUpImg {
  width: 70%;
  height: 80%;
  object-fit: cover;
}

.levelUpTextContent {
  margin: 20px auto;
  width: 65vw;
}
.textContentTitle {
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  font-style: italic;
}
.inlineInfo {
  font-size: 16px;
  font-weight: 500;
  color: #c9c9c9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.Level_bottomSection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 375px) {
  .card-container {
    max-height: 38vh;
  }
}
