@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.settings_contentDiv {
  font-family: "Roboto", sans-serif;
  position: relative;
  height: 100vh;
}

.titleBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.settingsImg {
  width: 50px;
  height: 50px;
}
.iconTitle {
  font-size: 30px;
  font-weight: 900;
  color: #c9c9c9;
}
.subTitle {
  font-size: 20px;
  font-style: italic;
  font-weight: 800;
  color: #c9c9c9;
}
.dropdown_wrapper_Settings {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 2px solid #c9c9c9;
  border-radius: 25px;
  padding: 5px 10px;
  margin-top: 20px;
  gap: 5px;
}
.selectLangleftTitle {
  font-size: 14px;
  font-weight: 700;
  color: #c9c9c9;
  padding-right: 7px;
}
.sound_settings {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 5px;
  border: 1px solid #c9c9c9;
  border-radius: 30px;
  padding: 5px;
  font-weight: 800px;
  margin-top: 20px;
}

.soundText {
  font-size: 18px;
  font-weight: 700;
  color: #c9c9c9;
  letter-spacing: 1px;
}
.soundsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: #322e32;
  padding: 10px;
  border-radius: 8px;
}
.soundActive {
  background: #161516;
  padding: 5px 10px;
  color: #c9c9c9;
  font-weight: bold;
  border: 1px solid #c9c9c9;
  border-radius: 8px;
}
.contactSupport {
  margin: 20px auto;
}
.supportTitle {
  font-weight: 800;
  font-size: 25px;
  letter-spacing: 1px;
  color: #c9c9c9;
}
.subjectInput {
  border-radius: 30px;
  display: flex;
  border: 2px solid #c9c9c9;
  padding: 10px;
}
.subjectText {
  font-size: 17px;
  font-weight: 700;
  color: #c9c9c9;
  letter-spacing: 1px;
}
.subjectField {
  background-color: transparent;
  border: none;
  color: #c9c9c9;
  width: 90%;
}
.subjectField:focus {
  border: none;
  outline: none;
}
.textAreaBox {
  margin: 15px auto;
  border-radius: 10px;
  border: 1px solid #c9c9c9;
  padding: 10px;
}
.textAreaInput {
  font-size: 18px;
  font-weight: 800;
}
.textAreaInput::placeholder {
  font-size: 18px;
  color: #c9c9c9;
  font-weight: 800;
}
.buttonSubmit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sendButton {
  font-weight: 18px;
  font-weight: 800;
  color: #c9c9c9;
  text-align: center;
  padding: 8px 12px;
  border: 1px solid #c9c9c9;
  border-radius: 10px;
  background-color: #322e32;
  text-transform: uppercase;
}
/* Media query for mobile screens */
@media (max-width: 768px) {
}
