/* KycComponent.css */

.profileMainDiv {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: #1D1C1D;
  position: relative;
  margin: 0;
  padding: 0;
}

.overlay {
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
}
.radial1 {
  display: flex;
  width: 100%;
  position: absolute;
  top: 7rem;
  width: 150px;
  height: 180px;
  /* background: rgba(91, 17, 12, 0.955); */
  filter: blur(100px);
}
.radial2 {
  display: flex;
  width: 100%;
  position: absolute;
  width: 200px;
  height: 200px;
  /* background: rgba(18, 73, 131, 0.673); */
  filter: blur(70px);
  bottom: 8rem;
}
.profileDetailBox_2rx{
  display: flex;
  flex-direction: column;
  width: 100vw;
  z-index: 1100;
  height: 100vh;
  position: absolute;
  overflow-y: scroll;
}
.profileCard {
  width: 100vw;
  position: absolute;
  background: #1D1C1D;
  border-radius: 10px;
  /* Keeping the desired border-radius value */
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid transparent;
  border-image: linear-gradient(to right, #44388c, #796dbbe3);
  border-image-slice: 1;
  margin-bottom: 20px;
}

.profileCardImg {
  width: 48px;
}

.ProfileCardTitle {
  font-size: 16px;
  font-weight: 600;
  color: white;
  margin: 5px;
}

.ProfileCardBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.ProfileCardBottom p {
  font-size: 13px;
  color: #afafaf;
  margin: 0;
}

.ProfileCardBottom img {
  width: 36px;
}

.kycInputDiv P {
  color: #c7c1de;
  margin: 0;
  margin-bottom: 6px;
}

.kycInputDiv P {
  color: #c7c1de;
  margin: 0;
  margin-bottom: 6px;
  font-size: 14px;
  text-transform: uppercase;
}

.kycInputDiv input {
  border-radius: 99px;
  padding: 14px;
  color: white;
  display: block;
  width: 92%;
  margin-bottom: 20px;
  border: 1px solid #32303e;
  background: rgba(255, 255, 255, 0.1);
  font-size: 14px;
  outline: none;
}

.kycInputDiv input::placeholder {
  color: #79757c;
}

.kycInputSubmit {
  text-align: center;
  display: block;
  width: 100%;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  border-radius: 99px;
  border: none;
  background: #373739;
}

.kycInputSubmitGo {
  background: #d87500 !important;
  color: white;
  padding: 12px;
}

.profileLoaderDiv {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  z-index: 1;
  height: 100vh;
  width: 100vw;
  background-color: #0000005e;
  transform: translate(-50%, -50%);
}

.profileLoaderDiv img {
  width: 60px;
  height: 60px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  animation: spin 1s linear infinite;
}
.formBox {
  background: rgba(101, 76, 192, 0.212);
  padding: 25px;
  border-radius: 15px;
  height: min-content;
  margin-bottom: 6rem;
}
.profileCard_KYC {
  background: #1D1C1D;
  border-radius: 10px;
  /* Keeping the desired border-radius value */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: rgba(101, 76, 192, 0.212);

  margin-bottom: 20px;
}
.profileCardImgBox {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.205);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profileCardImgBox img {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95px;
  height: 50px;
}
.profilePic {
  position: relative;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profileRing img {
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileImgBox {
  position: absolute;
  width: 50px;
  height: 50px;
}
.profileImgBox img {
}
.ppBox {
  display: flex;
  /* width: 100%; */
}
.profileContainer {
  position: relative;
}
.ppl {
  display: flex;
  top: 0;
}

.ppLevel {
  width: 117px;
  height: 81px;
}
.profilepic {
  position: absolute;
  top: 11.4px;
  left: 26.9px;
}
.frogImg {
  width: 62px;
  height: 60px;
  position: absolute;
  border-radius: 50%;
  object-fit: cover;
}
.camera {
  position: absolute;
  top: 65px;
  left: 73.9px;
  background-color: red !important;
}
.profile-congrats-box {
  position: fixed;
  top: 0;
}
.cameraIcon {
  width: 23px;
  height: 18px;
  position: absolute;
  background: #000;
}
