@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 2s infinite;
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 2s infinite;
}

#outerContainer {
  position: absolute;
  top: 19.5%;
  right: 8%;
  display: inline-block;
}
#outerContainer_wallet {
  position: absolute;
  top: 19.5%;
  left: 8%;
  display: inline-block;
}
#outerContainer_leader {
  position: absolute;
  top: 20%;
  left: 8%;
  display: inline-block;
}
#okxIconDiv {
  position: absolute;
  top: 37%;
  left: 8.5%;
  display: inline-block;
}

#bitgetDiv {
  position: absolute;
  top: 45%;
  left: 8.5%;
  display: inline-block;
}
#airDropDiv {
  position: absolute;
  top: 28%;
  left: 8.5%;
  display: inline-block;
}
#airDropContent {
  background-color: rgb(0, 0, 0);
  padding: 2px;
}

#container {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.circle {
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.15); /* Light border for animation */
  width: 30px; /* Initial size of the circle */
  height: 30px;
  position: absolute;
  z-index: 1;
  opacity: 0; /* Start with invisible */
  animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
  translate: 14px 15px; /* Position adjustment */
}

@keyframes scaleIn {
  from {
    transform: scale(1); /* Start from original size */
    opacity: 1; /* Fully visible at the start */
  }
  to {
    transform: scale(2.5); /* Expand to 2.5 times the original size */
    opacity: 0; /* Fade out at the end */
  }
}
