.bottomButtons {
  width: 92%;
  left: 4%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  bottom: 18px;
  border: 2px solid transparent;
  border-radius: 8px;
  background-image: linear-gradient(#32363cf7, #32363cf7),
    linear-gradient(90deg, #fff5097a 1.09%, #35b6b27a 51.5%, #666666b8 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  cursor: pointer;
  transition: all 0.3s ease;
  /* z-index: 15; */
}

.navButtons {
  height: 60px;
  width: 100%;
  display: flex;
  gap: 2px;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.middleButton {
  position: absolute;
  z-index: 1200012;
  background: #32363cf7;
  height: 80px;
  width: 80px;
  margin: 0px 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  left: 43%;
  transform: translate(-50%);

  border: 2px solid transparent;
  background-image: linear-gradient(#32363cf7, #32363cf7),
    linear-gradient(90deg, #fff5097a 1.09%, #35b6b27a 51.5%, #666666b8 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  cursor: pointer;
  transition: all 0.3s ease;
}

.buttonDiv {
  /* width: 56px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
}

.activeNav {
  background: #212429 !important;
  text-decoration: none;
}

.activeNav span {
  background: #212429;
  color: white !important;
}

.buttonIcon {
  width: 40px;
  height: 40px;
}

.buttonText {
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: gray;
}

.buttonChildDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.buttonChildDiv span {
  font-size: 10px;
}

@media (max-width: 380px) {
}
