@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.body {
  background: #1d1c1d;
  margin: 0;
  padding: 0;
  height: 100vh;
  /* overflow-y: scroll; */
  width: 100vw;
  position: relative;
  font-family: "Roboto", sans-serif;
}
.shareFriends {
  margin: 20px 0px;
  text-align: center;
  width: 85%;
}

.friendsTitle {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #c9c9c9;
  margin: 15px auto;
}
.friendsEarnDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 10px;
  margin: 5px 0px;
}
.friendsEarn {
  margin-top: 10px;
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.friendCredits {
  display: flex;
  align-items: center;
  gap: 2px;
}
.friendCredits span {
  font-size: 25px;
  font-weight: bold;
  color: white;
}

.earnBoxText {
  font-size: 12px;
  font-weight: bold;
  color: white;
  letter-spacing: 1px;
}

.viewMore {
  width: 80vw;
  text-align: center;
  border: 2px solid #d9d9d9;
  border-radius: 15px;
  padding: 10px;
  color: "white";
  margin-bottom: 10px;
}
.viewMore span {
  font-size: 17px;
  font-weight: bolder;
  letter-spacing: 1px;
  color: white;
}
.closeList {
  width: 64vw;
  text-align: center;
  border: 2px solid #bfa75d;
  border-radius: 15px;
  padding: 10px;
  color: "white";
  font-size: 20px;
  color: white;
  font-weight: 800;
  letter-spacing: 1px;
  margin: 10px auto;
}
.inviteDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 88vw;
  margin: 8px 0px;
  gap: 4px;
}

.textDiv {
  width: 90%;
  padding: 5px;
  background: #bfa75d;
  color: #e4d6d6;
  border-radius: 12px;
}
.textDiv span {
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 1px;
}

.inviteIconDiv {
  padding: 3px;
  background: #bfa75d;
  color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyIcon {
  margin-top: 4px;
  text-align: center;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.statsItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-bottom: 2px solid rgba(137, 130, 130, 0.42);
  margin-bottom: 10px;
}

.statContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}
.pfBar {
  width: 93vw;
}

.inviteEarnBox {
  width: 90%;
  margin-top: 8px;
  background-color: rgba(255, 255, 255, 0.035);
  border: 1px solid rgba(255, 255, 255, 0.041);
  backdrop-filter: blur(5px);
  padding: 10px 0;
  border-radius: 16px;
  color: white;
}

.inviteEarnHeader {
  font-size: 18px;
  margin-bottom: 10px;
  padding: 0 1.5rem 14px 1rem;
  color: rgba(137, 234, 255, 1);
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  border-bottom: 1px solid rgba(255, 255, 255, 0.073);
}

.inviteOptions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 0 8px;
  overflow-x: scroll;
}
.inviteOptions::-webkit-scrollbar {
  display: none;
}

.inviteOption {
  /* width: 6rem; */
  background-color: rgba(255, 255, 255, 0.045);
  border: 1px solid rgba(255, 255, 255, 0.011);
  backdrop-filter: blur(10px);
  padding: 5px 10px 0 7px;
  border-radius: 6px;
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 4px;
  position: relative;
}
.completed {
  background-color: rgba(17, 144, 8, 0.119);
}
.inviteText {
  width: 7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  color: rgb(255, 255, 255);
  font-family: "Oswald", sans-serif;
  font-weight: 350;
}
.checkMark {
  font-size: 1rem;
  color: rgb(11, 211, 11);
  padding: 0;
  margin: 0;
}
.inviteAmount {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inviteOption img {
  width: 25px;
  margin-top: 4px;
}
.amount {
  margin: 0 0 3px 7px;
  font-size: 15px;
  letter-spacing: 1px;
  color: rgb(255, 255, 255);
  font-family: "Oswald", sans-serif;
  font-weight: 400;
}
.inviteButtons {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.inviteButton {
  background-color: rgba(255, 255, 255, 0.01);
  border: 1px solid rgba(255, 255, 255, 0.011);
  backdrop-filter: blur(10px);
  padding: 7px 2rem;
  border-radius: 20px;
  font-size: 13px;
  color: rgb(255, 255, 255);
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  text-align: center;
  margin: 0 5px 0 0;
  display: flex;
  align-items: center;
}
.inviteButton2 {
  background-color: rgba(255, 255, 255, 0.01);
  border: 1px solid rgba(255, 255, 255, 0.011);
  backdrop-filter: blur(10px);
  padding: 8px;
  border-radius: 3rem;
  font-size: 13px;
  color: rgb(255, 255, 255);
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  text-align: center;
  margin: 0;
  display: flex;
  align-items: center;
}

.linkIcon {
  margin-right: 5px;
  color: rgba(255, 218, 0, 1);
  font-size: 1.2rem;
}

/* .copyIcon {
  margin: 0;
  color: rgba(216, 117, 0, 1);
  font-size: 1rem;
} */

.invitedBox {
  width: 93%;
  margin-bottom: 15px;
  padding: 8px 0 20px 0;
  color: white;
  margin-top: -10px;
}

.friendListDiv {
  width: 100%;
  margin-bottom: 0px;
  padding: 8px 0 20px 0;
  color: white;
  margin-top: -10px;
}

.friendListScrollable {
  height: 35vh;
  overflow-y: scroll;
}
.bonusListDiv {
  width: 100%;
  padding: 12px 0 0px 0;
  color: white;
  margin-top: -10px;
  height: 45vh;
  overflow-y: scroll;
}

.bonusListDiv table td {
  border: none;
  border-bottom: 1px solid #89828263;
}

.invitedHeader {
  margin-top: 10px;
  color: white;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 1px;
  padding: 5px;
  border-bottom: 2px solid gray;
}

.userLists {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #3e36366b;
  padding: 5px 0px;
}
.listRow {
  display: flex;
  align-items: center;
  gap: 4px;
}
.creditsBox {
  width: 35%;
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 800;
  color: #fdb238;
}
.listProfileIcon {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.showMore {
  width: 64vw;
  text-align: center;
  border: 2px solid #d9d9d9;
  border-radius: 15px;
  padding: 10px;
  color: "white";
  margin: -8px auto;
  font-size: 20px;
  color: white;
}

.invitedItem {
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.041);
  background: rgba(255, 255, 255, 0.053);
  background-blend-mode: overlay;
  backdrop-filter: blur(40px);
  padding: 10px 12px;
  border-radius: 3rem;
  margin-bottom: 10px;
}

.ppl {
  display: flex;
  top: 0;
}

.ppLevel {
  width: 57px;
  height: 40px;
}
.profilePic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
.profilepic {
  position: absolute;
  top: 15px;
  left: 25.5px;
  /* width: 36px;
    height: 36px; */
  position: absolute;
  border-radius: 50%;
}

.invitedItem .userInfo {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 10px;
}

.userName {
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 1px;
}

.userCredits {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.CreditsMoney {
  width: 30px;
}
.creditsAmount {
  margin-left: 5px;
  font-size: 14px;
  font-family: "Oswald", sans-serif;
  color: white;
  letter-spacing: 2px;
}

.userStatus {
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  font-family: "Oswald", sans-serif;
  color: white;
}
.statusDot {
  border: 2.5px solid rgba(0, 0, 0, 0.27);
  margin-right: 6px;
  width: 8px;
  height: 8px;
  border-radius: 3rem;
  background-color: rgba(33, 169, 39, 1);
}
.statusDot2 {
  border: 2.5px solid rgba(0, 0, 0, 0.27);
  margin-right: 6px;
  width: 8px;
  height: 8px;
  border-radius: 3rem;
  background-color: rgba(249, 249, 249, 0.547);
}

.checkIcon {
  background-color: white;
  border-radius: 100%;
  width: 15px;
  margin: 5px;
  top: 1px;
  position: relative;
}

@media (max-width: 350px) {
  .invitedBox {
    width: 95%;
    height: 150px;
  }
  .invitedList {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 375px) and (max-height: 667px) {
  .invitedBox {
    width: 95%;
    height: 130px;
    margin-bottom: 0px;
  }
  .showMore {
    margin: 5px auto;
  }
  .bonusListDiv {
    height: 35vh;
  }
}
