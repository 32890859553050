/* Full width border for the entire container */
.container {
  width: 93vw;
  margin: 8px auto;
  border-radius: 9px;
  /* background: rgba(101, 76, 192, 0.212); */
  color: #ffffff4b;
  background: linear-gradient(to bottom, rgba(101, 76, 192, 0.212));
  padding: 5px;
}

/* Styling the PARTNERS heading */
.header {
  text-align: left;
  color: rgb(137, 234, 255);
  font-size: 17px;
  font-family: Oswald;
  padding: 0px 3px;
  position: relative; /* Add this to position the ::after correctly */
  margin-bottom: 40px; /* Add some bottom space for better layout */
  margin-top: 15px;
}

/* Adding a thin white line below the PARTNERS heading */
.header::after {
  content: "";
  display: block;
  width: 93vw;
  height: 1px;
  background-color: white; /* Thin white line */
  margin-top: 25px; /* Adjusted margin to prevent pushing too far */
  position: absolute;
  left: 0;
  bottom: -20px; /* Adjusted bottom to make it appear just below the text */
}
.radial1 {
  display: flex;
  width: 100%;
  position: absolute;
  top: 7rem;
  width: 150px;
  height: 180px;
  background: rgba(91, 17, 12, 0.955);
  filter: blur(100px);
}
.radial2 {
  display: flex;
  width: 100%;
  position: absolute;
  width: 200px;
  height: 200px;
  background: rgba(18, 73, 131, 0.673);
  filter: blur(70px);
  bottom: 8rem;
}

/* Border for each partner card */
.partner {
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
  background: linear-gradient(to left, rgba(101, 76, 192, 0.212), transparent),
    linear-gradient(to right, rgba(101, 76, 192, 0.212), transparent);
}

/* Styling for partner images */
.partner-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Partner info container */
.partner-info-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

/* Partner info styling */
.partner-info {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center align the content vertically */
  flex-grow: 1;
  margin-right: 10px; /* Space for the vertical line */
  width: 100%;
}

/* Title styling */
.partner-info .title {
  font-size: 14px; /* Decreased font size */
  font-weight: 300; /* Decreased font weight */
  color: white; /* White color for the text */
}

/* Subtitle styling */
.partner-info .subtitle {
  font-size: 12px;
  color: #b0a8b9;
}

/* Vertical line styling */
.vertical-line {
  height: 40px; /* Adjust the height as needed */
  width: 0.5px;
  background-color: white; /* Vertical line color */
  margin: 0 10px; /* Space between the name/reward and the vertical line */
  margin-left: -40px;
}

/* Reward section styling */
.reward-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between the reward and the arrow */
  width: 100%; /* Ensure it takes up remaining space */
}

/* Reward section styling */
.reward {
  display: flex;
  align-items: center;
}

/* Coin image styling */
.coin-image {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

/* Reward amount styling */
.reward .amount {
  font-size: 16px;
  color: #ffd700;
}

/* Arrow styling */
.arrow {
  font-size: 20px;
  color: #b0a8b9;
}

.partner.completed {
  opacity: 0.4; /* Optionally reduce the opacity to indicate completion */
}
