.modal {
    /* position: fixed;
    left: 0; */
    /* position: relative; */
    width: 100vw;
    height: 100vh;
    /* bottom: 0; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-between;
    z-index: 9999;
  }
  .confetti{
    z-index: 10000;
  }
  
  .modal-content {
    background-color: rgba(31, 1, 44, 0.933);  
    backdrop-filter: blur(5px);
    padding: 20px;
    padding-top: 0px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* max-width: 95%;
    width: 80%; */
    width: 93vw;
    height: 450px;
    /* overflow-y: auto; */
    z-index: 9999;
     /* position: fixed; */
    /* bottom: -14rem; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.congrats-box{
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 5rem; */
    /* background: linear-gradient(95.39deg, #7F35DD 0%, #2900CD 103.77%); */
    /* text-align: center; */
    /* color: rgba(255, 255, 255, 1); */
    /* top: 0; */
    /* margin: -11rem 0 0 0; */
    /* font-size: 20px; */
    /* border-radius: 8px; */
    position: absolute;
    left: 0;
    /* top: -3rem; */
    /* width: 93vw; */
    /* transform: translate(-50% ,-100%); */
}

.modal-tilte{
    font-size: 14px;
    color: white;
    font-weight: normal;
    text-align: center;
}

.bottom-text{
    width: 15rem;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    background-color: rgba(166, 79, 223, 0.116);  
    backdrop-filter: blur(5px);
    padding: 12px;
    border-radius: 99px;
    color: white;
}

.cld-img{
    width: 80px;
    margin-top: -30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -15px;
}

.all-cd-card{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 10px;
    margin-bottom: 10px;
}
  
.green-div{
    background: rgba(0, 149, 15, 0.211);
    height: 85px;
    width: 85px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}
.green-div p{
    /* background: white; */
    background: rgb(0, 163, 14);
    color: white;
    font-weight: 600;
    font-size: 24px;
    height: 38px;
    width: 38px;
    border-radius: 99px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.decorated-dark-div{
    /* background: linear-gradient(rgba(58, 33, 215, 0.3),rgba(122, 50, 239, 1)); */
    background-color: rgba(255, 255, 255, 0.148);
    backdrop-filter: blur(10px);
    height: 82px;
    width: 83px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid rgba(255, 218, 0, 1);
}
.reward-text_23{
    color: rgba(243, 211, 43, 1);
    text-align: center;
    font-size: 12px;
    line-height: 15px;
    padding: 0 1px;
}
.decorated-dark-div p{
    line-height: 15px;
    margin: 0;
    color: white;
    font-weight: 600;
}
.dark-div{
    /* background: linear-gradient(rgba(58, 33, 215, 0.3),rgba(122, 50, 239, 1)); */
    background-color: rgba(255, 255, 255, 0.148);
    backdrop-filter: blur(10px);
    height: 85px;
    width: 85px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.dark-div p{
    margin: 0;
    color: white;
    font-weight: 600;
}

@media (max-width: 380px){
    .green-div{
        width: 20vw;
        height: 20vw;
    }
    .dark-div{
        width: 20vw;
        height: 20vw;
    }
    .decorated-dark-div{
        width: 20vw;
        height: 20vw;
    }
    .reward-text_23{
        font-size: 2.8vw;
    }
}

.announcementContainer{
    /* width: 100vw; */
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: scroll;
    /* width: 90%; */
    padding: 10px 0;
}
.announcementContainer::-webkit-scrollbar{
    display: none;
}
.announcementBox {
    flex: 0 0 auto;
    width: 300px;
    margin: 20px 10px;
    /* background-color: rgba(255, 255, 255, 0.035); */
    /* border: 1px solid rgba(255, 255, 255, 0.041); */
    /* backdrop-filter: blur(5px); */
    padding: 20px 20px 3.5rem 20px;
    border-radius: 16px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
  
  .announcementImage {
    width: 280px;
    height: 200px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .announcementTitle {
    font-size: 22px;
    color: rgb(255, 255, 255);
    font-family: "Oswald", sans-serif;
    font-weight: 600;
    margin: 0.5rem 0 0.5rem 0;
    text-align: center;
    line-height: 22px;

  }
  
  .announcementContent {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.827);
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    margin-top: 1rem;
    line-height: 17px;
    text-align: center;
  }